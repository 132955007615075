import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Input, message, Table, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const OfficeManager = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [offices, setOffices] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentOffice, setCurrentOffice] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchOffices();
  }, []);

  const fetchOffices = async () => {
    try {
      const response = await axios.get('https://kirsupapi.aakirsub.com/api/offices');
      setOffices(response.data);
    } catch (error) {
      console.error('Error fetching offices:', error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (editId) {
        await axios.patch(`https://kirsupapi.aakirsub.com/api/offices/${editId}`, values);
        message.success('Office updated successfully!');
        setEditId(null);
      } else {
        await axios.post('https://kirsupapi.aakirsub.com/api/offices', values);
        message.success('Office added successfully!');
      }
      form.resetFields();
      fetchOffices();
    } catch (error) {
      console.error('Error submitting office data:', error);
      message.error(editId ? 'Failed to update office. Please try again.' : 'Failed to add office. Please try again.');
    }
  };

  const handleUpdate = (record) => {
    updateForm.setFieldsValue({
      floor: record.floor,
      offices: record.offices.join(', '),
    });
    setCurrentOffice(record);
    setEditId(record._id);
    setIsModalVisible(true);
  };

  const handleUpdateSubmit = async () => {
    try {
      const values = await updateForm.validateFields();
      await axios.patch(`https://kirsupapi.aakirsub.com/api/offices/${currentOffice._id}`, values);
      message.success('Office updated successfully');
      setIsModalVisible(false);
      fetchOffices();
    } catch (error) {
      console.error('Validation failed:', error);
      message.error('Failed to update office. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://kirsupapi.aakirsub.com/api/offices/${id}`);
      message.success('Office deleted successfully');
      fetchOffices();
    } catch (error) {
      console.error('Error deleting office:', error);
      message.error('Failed to delete office. Please try again.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentOffice(null);
    setEditId(null);
  };

  const columns = [
    {
      title: 'Floor',
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: 'Offices',
      dataIndex: 'offices',
      key: 'offices',
      render: (offices) => offices.join(', '),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div>
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => handleUpdate(record)} style={{ marginRight: 8 }}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => handleDelete(record._id)} style={{ color: 'white' }}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="floor" label="Floor" rules={[{ required: true, message: 'Please input the floor!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="offices" label="Offices (comma separated)" rules={[{ required: true, message: 'Please input the offices!' }]}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={offices} rowKey="_id" style={{ marginTop: 20 }} />

      <Modal
        title="Update Office"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleUpdateSubmit}
      >
        <Form form={updateForm} layout="vertical">
          <Form.Item name="floor" label="Floor" rules={[{ required: true, message: 'Please input the floor!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="offices" label="Offices (comma separated)" rules={[{ required: true, message: 'Please input the offices!' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OfficeManager;
