import React, { useState, useEffect } from "react";
import { Card, Button, Pagination } from "antd";
import { motion } from "framer-motion";
import "tailwindcss/tailwind.css";
import { Link } from "react-router-dom";
import Img from "../../../assets/Kirkos Logo.png"; // Replace with the actual path to the logo
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS
import { useTranslation } from "react-i18next";

const OfficesList = () => {
  const { t } = useTranslation();
  const [offices, setOffices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await fetch("https://kirsupapi.aakirsub.com/api/addoffice");
        const data = await response.json();
        setOffices(data);
      } catch (error) {
        console.error("Error fetching offices:", error);
      }
    };

    fetchOffices();
  }, []);

  // Calculate the indexes of the items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = offices.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="min-h-screen bg-white text-[#0a4275] p-6">
      <h1 className="text-4xl font-extrabold text-center mb-12 text-[#f5a623]">
        {t('Kirkos Subcity Administration Offices')}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {currentItems.map((office, index) => (
          <motion.div
            key={office._id}
            className="w-full"
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`}
          >
            <Card
              hoverable
              cover={
                <div className="flex items-center justify-center h-56">
                  <img
                    alt={office.name}
                    src={`https://kirsupapi.aakirsub.com/uploads/Offices/${office.logo}`} // Adjust the URL path to your image folder
                    className="object-fill w-full h-full  rounded-t-lg"
                  />
                </div>
              }
              className="bg-[#0a4275] text-white shadow-xl rounded-lg overflow-hidden transform transition-transform duration-500 hover:scale-105"
            >
              <Card.Meta
                title={
                  <h2 className="text-xl mx-auto text-center font-semibold text-white">
                    {office.name}
                  </h2>
                }
                description={
                  <div className="text-center mt-4">
                    <Link
                      to={`/management/offices/${office._id}`}
                      className="text-center mt-4 text-yellow-500"
                    >
                      {t('READ MORE')}
                    </Link>
                  </div>
                }
              />
            </Card>
          </motion.div>
        ))}
      </div>
      <div className="flex justify-center mt-8">
        <Pagination
          current={currentPage}
          total={offices.length}
          pageSize={itemsPerPage}
          onChange={onPageChange}
          showSizeChanger={false}
          className="text-center"
        />
      </div>
    </div>
  );
};

export default OfficesList;
