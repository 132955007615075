import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const WoredaDetail = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchWoredaDetail = async () => {
      try {
        const response = await axios.get(`https://kirsupapi.aakirsub.com/api/addworeda/${id}`);
        setDetail(response.data);
      } catch (error) {
        console.error('Error fetching woreda details:', error);
      }
    };

    fetchWoredaDetail();
  }, [id]);

  if (!detail) {
    return <p>Loading...</p>;
  }

  // Split coreValues string into an array
  const coreValuesList = detail.coreValues.split(',').map((value) => value.trim());

  return (
    <div className="bg-gradient-to-r from-blue-500 to-green-500 text-white py-16 px-4 lg:px-8 min-h-screen">
      <div className="container mx-auto">
        <Link to="/management/woredas" className="text-white mb-4 inline-block">
          &larr; {t('Back')}
        </Link>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          <h1 className="text-4xl font-extrabold text-center mb-6">
            {detail.name}
          </h1>
          <div className="bg-white text-[#0a4275] p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">{t('About Kirkos Subcity')}</h2>
            <p className="text-lg mb-6">{detail.floor}</p>
            <h2 className="text-2xl font-bold mb-4">{t('Vision')}</h2>
            <p className="text-lg mb-6">{detail.vision}</p>
            <h2 className="text-2xl font-bold mb-4">{t('Mission')}</h2>
            <p className="text-lg mb-6">{detail.mission}</p>
          <h2 className="text-2xl font-bold mb-4">{t('Core Values')}</h2>
            <ul className="list-disc list-inside space-y-2 mb-6">
              {coreValuesList.map((value, index) => (
                <li key={index}>{value}</li>
              ))}
            </ul>
            <h2 className="text-2xl font-bold mb-4">{t('Contact Information')}</h2>
            <p className="flex items-center mb-2">
              <FaPhone className="mr-2" /> {detail.phoneNumber}
            </p>
            <p className="flex items-center mb-2">
              <FaEnvelope className="mr-2" /> {detail.email}
            </p>
            <p className="flex items-center">
              <FaMapMarkerAlt className="mr-2" /> {detail.address}
            </p>
          </div>
          <div className="relative w-full h-96 sm:h-[450px] mt-8">
            <iframe
              src={detail.location}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Location"
              className="absolute inset-0 w-full h-full rounded-lg shadow-lg"
            ></iframe>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default WoredaDetail;
