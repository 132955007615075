import React, { useState, useEffect } from "react";
import { Card, Pagination } from "antd";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../../../components/Loader"; // Import the Loader component
import { useTranslation } from "react-i18next";
const CabinetList = () => {
  const [cabinets, setCabinets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state
  const itemsPerPage = 20; // Number of items per page
  const { t, i18n } = useTranslation();
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const fetchCabinets = async () => {
      try {
        const response = await fetch("https://kirsupapi.aakirsub.com/api/cabinets");
        const data = await response.json();
        setCabinets(data);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching cabinets:", error);
        setLoading(false); // Ensure loading is set to false even on error
      }
    };

    fetchCabinets();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cabinets.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container mx-auto p-4 lg:p-8">
      <h1 className="text-3xl font-bold text-center mb-6 text-[#0a4275]">
        {t('KIRKOS SUB CITY')}
      </h1>
      <h2 className="text-2xl font-bold text-center mb-6 text-[#0a4275]">
        {t('Cabinet Members')}
      </h2>

      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <Loader />{" "}
        </div> // Show loader while data is being fetched
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
            {currentItems.map((cabinet) => (
              <motion.div
                key={cabinet._id}
                className="flex justify-center"
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, type: "spring", stiffness: 120 }}
                data-aos="fade-up"
                data-aos-offset="200"
              >
                <Card className="w-full max-w-xs bg-[#0a4275] text-gray-800 shadow-lg rounded-lg hover:shadow-2xl hover:scale-105 transition-shadow">
                  <div className="card-image-container">
                    <img
                      alt={cabinet.name}
                      src={`https://kirsupapi.aakirsub.com/${cabinet.img}`}
                      className="card-image w-full h-48 object-cover rounded-t-lg mb-3 "
                    />
                  </div>
                  <Card.Meta
                    title={
                      <span className="text-lg font-bold text-[#ffffff]">
                        {cabinet.name}
                      </span>
                    }
                    description={
                      <span className="text-yellow-500 font-bold">
                        {cabinet.title}
                      </span>
                    }
                    className="text-center mt-4"
                  />
                </Card>
              </motion.div>
            ))}
          </div>
          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={cabinets.length}
              onChange={handlePageChange}
              className="bg-gray-100 rounded-lg p-2 shadow-md"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CabinetList;
