import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkedAlt,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaArrowLeft,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
const OfficeDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [office, setOffice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOffice = async () => {
      try {
        const response = await fetch(`https://kirsupapi.aakirsub.com/api/addoffice/${id}`);
        if (response.ok) {
          const data = await response.json();
          setOffice(data);
        } else {
          setError("Office details not found.");
        }
      } catch (error) {
        setError("An error occurred while fetching the office details.");
      } finally {
        setLoading(false);
      }
    };

    fetchOffice();
  }, [id]);

  if (loading) {
    return (
      <div className="bg-gray-100 text-gray-800 py-16 px-4 lg:px-8">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-extrabold text-blue-600 mb-6">Loading...</h1>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-gray-100 text-gray-800 py-16 px-4 lg:px-8">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-extrabold text-red-600 mb-6">{error}</h1>
          <button
            onClick={() => navigate('/management/offices')}
            className="text-blue-600 hover:text-blue-800"
          >
            Go back to offices list
          </button>
        </div>
      </div>
    );
  }

  if (!office) {
    return (
      <div className="bg-gray-100 text-gray-800 py-16 px-4 lg:px-8">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-extrabold text-red-600 mb-6">
            Office Details Not Found
          </h1>
          <button
            onClick={() => navigate('/management/offices')}
            className="text-blue-600 hover:text-blue-800"
          >
            Go back to offices list
          </button>
        </div>
      </div>
    );
  }

  const { name, vision, mission, coreValues, phoneNumber, email, address, floor, logo } = office;

  return (
    <div className="bg-gradient-to-r from-blue-500 to-green-500 text-gray-800 py-16 px-4 lg:px-8">
      <div className="container mx-auto">
        <button
          onClick={() => navigate(-1)}
          className="mb-6 flex items-center text-blue-600 hover:text-blue-800 transition-colors"
        >
          <FaArrowLeft className="mr-2 text-white" /> <span className="text-white">{t('Back')}</span>
        </button>
        <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
          <div className="flex items-center justify-center mb-6">
            <img
              alt={name}
              src={`https://kirsupapi.aakirsub.com/uploads/Offices/${logo}`}
              // src={`https://kirsupapi.aakirsub.com/uploads/Offices${logo}`} // Adjust the URL path to your image folder
              className="object-fit w-56 h-56 rounded-full"
            />
          </div>
          <h1 className="text-4xl font-extrabold text-[#0a4275] mb-6">{name}</h1>
          <div className="mb-12">
            <h2 className="text-3xl font-semibold text-[#0a4275] mb-4">{t('Vision')}</h2>
            <p className="text-lg mb-6">{vision}</p>
            <h2 className="text-3xl font-semibold text-[#0a4275] mb-4">{t('Mission')}</h2>
            <p className="text-lg mb-6">{mission}</p>
            <h2 className="text-3xl font-semibold text-[#0a4275] mb-4">{t('Core Values')}</h2>
            <p className="text-lg whitespace-pre-line">{coreValues}</p>
          </div>
          <div>
            <h2 className="text-3xl font-semibold text-[#0a4275] mb-4">{t('Contact Information')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              {phoneNumber && (
                <div className="flex items-center space-x-3 mb-4">
                  <FaPhone className="text-xl text-[#0a4275]" />
                  <p className="text-lg">{phoneNumber}</p>
                </div>
              )}
              {email && (
                <div className="flex items-center space-x-3 mb-4">
                  <FaEnvelope className="text-xl text-red-600" />
                  <p className="text-lg">{email}</p>
                </div>
              )}
              {address && (
                <div className="flex items-center space-x-3 mb-4">
                  <FaMapMarkedAlt className="text-xl text-gray-700" />
                  <p className="text-lg">{address}</p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 flex justify-center space-x-4">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-3xl text-blue-600 hover:text-blue-800 transition-colors"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-3xl text-blue-400 hover:text-blue-600 transition-colors"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-3xl text-pink-500 hover:text-pink-700 transition-colors"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="text-center mt-8">
            <h2 className="text-2xl font-semibold text-[#0a4275]">{t('Floor')}</h2>
            <p className="text-lg">{floor}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeDetail;
